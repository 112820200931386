.trajectoryList {
  overflow-y: auto;
  border-radius: 5px;
  padding: 3px;
}

.trajectoryItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
  max-height: 30px;
  color: white;
}

.selectedTrajectory {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.trajectoryName {
  font-size: 0.9em;
  max-width: 200px;
  font-family: monospace;
  color: white;
}

.trajectory-actions {
  display: flex;
  gap: 0.5rem;
}

.icon-button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 4px;
  opacity: 0.7;
  transition: opacity 0.2s;
  height: 16px;
}

.icon-button:hover {
  opacity: 1;
}
