.prompt-editor {
  width: 100%;
  height: 100%;
  position: relative;
}

.full-screen-video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: black;
}

/* Hide volume control */
video::-webkit-media-controls-volume-slider,
video::-webkit-media-controls-mute-button {
  display: none !important;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background: black;
}

.controls {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 0 40px;
  z-index: 10;
}

.play-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0 15px 0 0;
}

.play-button:hover {
  opacity: 0.8;
}

.scrubber {
  flex: 1;
  position: relative;
  height: 4px;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  margin: 0;
}

.progress {
  position: relative;
  height: 100%;
  background: #ffffff;
  transition: width 0.1s ease-out;
  border-radius: 4px;
}

.handle {
  position: absolute;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  background: #ffffff;
  border-radius: 50%;
  cursor: grab;
  transition: transform 0.1s ease;
}

.handle:hover,
.handle:active {
  transform: translateY(-50%) scale(1.2);
}

/* When dragging */
.handle:active {
  cursor: grabbing;
}

/* Make the hit area larger */
.scrubber:hover {
  height: 6px;
}

/* Hide native controls */
video::-webkit-media-controls {
  display: none !important;
}

video::-webkit-media-controls-enclosure {
  display: none !important;
}

.video-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.marker {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #00ff00;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
