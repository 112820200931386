@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

:root {
  --f-size: 5; /* Adjust this value to change the font size */
  --f-unit: 1vmin;
  --f: calc(var(--f-size) * var(--f-unit));
  --bg: #181717;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

.glitch-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 700; /* Use the bold weight */
  font-size: var(--f);
  line-height: 1.2; /* Adjust line-height for better readability */
  margin: auto;
  color: #fff;
  text-align: center;
  transform: scaleX(var(--scale, 1));
  animation: glitch-p 11s infinite alternate;
  position: relative;
  user-select: none;
}

.glitch-text::before,
.glitch-text::after {
  --top: 0;
  --left: 0;
  --v-height: 30%;

  --n-tenth: calc(var(--f-size) * 0.1 * var(--top));
  --t-cut: calc(var(--n-tenth) / var(--f-size) * 100%);
  --b-cut: calc(var(--t-cut) + var(--v-height));

  content: attr(data-text);
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;

  transform: translateX(calc(var(--left) * 100%));

  filter: drop-shadow(0 0 transparent);

  text-shadow:
    calc(var(--left) * -3em) 0 0.02em rgb(210, 210, 210),
    calc(var(--left) * -6em) 0 0.02em #474747;

  background-color: var(--bg);
  clip-path: polygon(
    0% var(--t-cut),
    100% var(--t-cut),
    100% var(--b-cut),
    0% var(--b-cut)
  );
}

.glitch-text::before {
  animation: glitch-b 1.7s infinite alternate-reverse;
}
.glitch-text::after {
  animation: glitch-a 3.1s infinite alternate;
}

@keyframes glitch-p {
  17% {
    --scale: 0.87;
  }
  31% {
    --scale: 1.1;
  }
  37% {
    --scale: 1.3;
  }
  47% {
    --scale: 0.91;
  }
  87% {
    --scale: 1;
  }
}

@keyframes glitch-a {
  10%,
  30%,
  50%,
  70%,
  90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%;
  }
  20% {
    --left: 0.005;
  }
  40% {
    --left: 0.01;
    --v-height: 20%;
    --top: 3;
  }
  60% {
    --left: 0.03;
    --v-height: 25%;
    --top: 6;
  }
  80% {
    --left: 0.07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: 0.083;
    --v-height: 30%;
    --top: 1;
  }
}

@keyframes glitch-b {
  10%,
  30%,
  50%,
  70%,
  90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%;
    --top: 10;
  }
  20% {
    --left: -0.005;
  }
  40% {
    --left: -0.01;
    --v-height: 17%;
    --top: 3;
  }
  60% {
    --left: -0.03;
    --v-height: 35%;
    --top: 6;
  }
  80% {
    --left: -0.07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: -0.083;
    --v-height: 30%;
    --top: 1;
  }
}
