.visibility-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 4px 0px;
  height: calc(1em + 8px);
}

.visibility-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: #fff;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.visibility-toggle:hover {
  opacity: 1;
}
