.collapsible {
  border-radius: 4px;
  position: relative;
}

.collapsible-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
  padding: 8px 8px 8px 8px;
  user-select: none;
  flex-wrap: nowrap;
}

.collapsible-header-left {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.collapsible-header-buttons {
  margin-left: auto;
  display: flex;
  align-items: center;
  height: 100%;
  padding-bottom: 0;
}

.collapsible-header h3 {
  margin: 0;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
}

.collapsible-content {
  padding: 0 8px 8px 8px;
  position: relative;
}

.collapsible-icon {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}

.collapsible.active {
  background-color: rgba(255, 255, 255, 0.1);
}

.collapsible.active .collapsible-header {
  background-color: rgba(255, 255, 255, 0.05);
}

.collapsible-header-left .vertical-line {
  position: absolute;
  left: 8px;
  top: 30px;
  bottom: 0;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
  z-index: 1;
}

.collapsible-header-right {
  display: flex;
  align-items: center;
  gap: 8px;
}

.confirm-edit-button {
  background: none;
  border: none;
  color: #4caf50;
  cursor: pointer;
  font-size: 1.2em;
  padding: 0 8px;
}

.confirm-edit-button:hover {
  color: #45a049;
}

.indent-guide {
  position: absolute;
  left: 14px;
  top: 30px;
  bottom: 8px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
  z-index: 1;
}

.collapsible-header-left span {
  color: #fff;
}
