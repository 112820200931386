.dataset-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 10px;
  width: 100%;
}

.dataset-button {
  display: flex;
  align-items: center;
  padding: 2px 2px;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
}

.dataset-button svg {
  margin-right: 6px;
}
