.dataset-navigator {
  padding: 1rem;
  border-radius: 4px;
}

.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  background-color: transparent;
  border: 1px solid #8f8f8f;
  border-radius: 4px;
  padding: 0.16rem 0.5rem;
  color: #fff;
}

.search-icon {
  color: #fff;
  margin-right: 0.5rem;
}

.search-bar input {
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  font-size: 0.75rem;
}

.file-row {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.file-row:last-child {
  border-bottom: none;
}

.file-row-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.file-row-bottom {
  display: flex;
  justify-content: flex-start;
}

.file-name {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-score {
  display: inline-block;
  background-color: #ffffff;
  color: #000000;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 0.75rem;
  font-weight: bold;
  margin-top: 4px;
}

.file-actions {
  display: flex;
  gap: 0.5rem;
}

.icon-button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 4px;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.icon-button:hover {
  opacity: 1;
}
