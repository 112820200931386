.error-message {
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 0.7em;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
  color: #d32f2f;
  margin: 10px auto 0;
  padding: 8px 30px;
  max-width: 80%;
  border: 1px solid hsla(0, 100%, 50%, 0.2);
}
