.import-file-form {
  padding: 16px;
  color: white;
  font-family: monospace;
  font-size: 12px;
}

.upload-icon {
  font-size: 24px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 8px;
}

.import-file-form h2 {
  font-size: 14px;
  margin: 0 0 16px 0;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.9);
}

.file-input-label {
  display: block;
  width: 100%;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 16px;
  transition: background-color 0.2s;
  font-size: 12px;
}

.file-input-label:hover:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.15);
}

.file-input-label.disabled {
  background-color: rgba(255, 255, 255, 0.05);
  cursor: not-allowed;
}

.button-group {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.import-button,
.cancel-button {
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.2s;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.9);
}

.import-button:hover:not(:disabled),
.cancel-button:hover:not(:disabled) {
  background-color: rgba(255, 255, 255, 0.1);
}

.import-button:disabled,
.cancel-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.progress-bar-container {
  margin-top: 16px;
}

.progress-bar {
  width: 100%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  transition: width 0.2s ease;
}

.progress-text {
  margin-top: 8px;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.7);
}

.hidden {
  display: none;
}

.file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
