.controlsContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1000;
  width: 300px;
  height: 100vh;
  font-family: monospace;
  font-size: 12px;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent; /* Firefox */
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.controlsContainer.open {
  width: 300px;
}

.controlsContainer.closed {
  width: 30px;
  background-color: rgb(49, 49, 49, 0.5);
}

.controlsContainer > *:not(:first-child) {
  transition: opacity 0.2s ease-in-out;
}

.controlsContainer.closed > *:not(:first-child) {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.controlsContainer::-webkit-scrollbar {
  width: 4px;
}

.controlsContainer::-webkit-scrollbar-track {
  background: transparent;
}

.controlsContainer::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
}

.collapsible {
  width: 100%;
}
