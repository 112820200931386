* {
  box-sizing: border-box;
}

.google-sign-in-button {
  display: block;
  position: relative;
  background: none;
  border: none;
  outline: none;
  cursor: default;
  margin-top: 10px;
  padding: 8px 30px;
  font-family: 'Roboto', sans-serif;
  font-size: 0.75em;
  letter-spacing: 0.35em;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid hsla(0, 0%, 0%, 0.2);
  margin: 0 auto;
  margin-top: 100px;
  color: black;
}

.google-sign-in-button:hover {
  background-color: #f8f8f8;
}
