.prompt-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.05);
}

.prompt-image {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.2);
}

.prompt-editor-button {
  padding: 6px 12px;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  font-size: 12px;
  transition: all 0.2s;
}

.prompt-editor-button:hover:not(:disabled) {
  background-color: rgba(255, 255, 255, 0.1);
}

.prompt-editor-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: rgba(128, 128, 128, 0.2);
}

.prompt-editor-button:hover:disabled {
  background-color: rgba(128, 128, 128, 0.2);
}

.prompt-editor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  z-index: 10;
}
