.home-view {
  display: flex;
  flex-direction: column;
}

.home-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}
