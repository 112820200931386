@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.glitch {
  position: relative;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
  letter-spacing: 5px;
  z-index: 1;
}

.glitch__color {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: #000;
  clip: rect(0, 900px, 0, 0);
  color: #fff;
}

.glitch__bg-animate {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWNgYGBgAAAABQABh6FO1AAAAABJRU5ErkJggg==);
  animation: glitch-bg-anim 0.5s infinite alternate linear;
}

.glitch__bg-animate--opposite {
  animation-direction: alternate-reverse;
}

@keyframes glitch-bg-anim {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 900px 0;
  }
}
