.header-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;
}

.header-button:hover {
  color: #ffffff;
}

.delete-button {
  color: #666;
}

.delete-button:hover {
  color: #ffffff;
}
