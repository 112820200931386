.pose-control {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  max-height: 400px;
  overflow-y: auto;
}

.pose-control::-webkit-scrollbar {
  width: 8px;
}

.pose-control::-webkit-scrollbar-track {
  background: transparent;
}

.pose-control::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.pose-control h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 8px 0;
}

.limb-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  cursor: pointer;
  width: 100%;
}

.limb-row span {
  flex: 1;
  text-align: left;
  color: white;
}

.limb-opacity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 8px;
}

.limb-opacity span {
  color: white;
}

.limb-opacity input {
  color: white;
  background-color: transparent;
  outline: none;
  -moz-appearance: textfield;
  border: none;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 0.3s ease;
}

.limb-opacity input:focus {
  border-bottom-color: white;
}

.limb-opacity input::-webkit-outer-spin-button,
.limb-opacity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.visibility-icon {
  color: #666;
  margin-left: 8px;
}

.limb-row input[type='checkbox'] {
  width: 16px;
  height: 16px;
}
