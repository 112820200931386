/* Visualizer.module.css */
.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: 'red';
}

.visualizerContainer {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.canvasContainer {
  flex: 1;
  overflow: hidden;
}

.title {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
}

.controlsContainer {
  width: 100vw;
  padding: 16px;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  position: relative;
  border-left: 5px solid #ccc;
  overflow-y: auto;
  background-color: 'red';
}

.frameTrackWrapper {
  height: 100px;
  width: 100%;
}

.resizeHandle {
  position: absolute;
  left: -5px;
  top: 0;
  bottom: 0;
  width: 10px;
  cursor: col-resize;
  background-color: black;
}

.resizeHandle:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Add this block */
}

.toolbarContainer {
  margin-bottom: 16px;
}

.translationFieldContainer {
  display: flex;
}

.translationField {
  display: flex;
  margin-right: 10px;
  width: 60px;
}

.sectionLabel {
  margin: 1em 0;
  font-size: 0.95em;
}
